<template>
  <section id="intro">
    <img src="../assets/malnutritionedchild.png" class="back-img"/>
    <router-link to="/about">
    <button class="btnn"
        data-aos="zoom-in"
        data-aos-duration="2500"
        data-aos-delay="500"
        data-aos-once="true"><p class="intro-text">HOW YOU CAN KNOW OUR FOUNDATION BETTER...</p></button></router-link>
  </section>

  <div class="animation-container">
    <span class="typed-text">
    <p class="lhead">AIM<span><svg xmlns="http://www.w3.org/2000/svg" fill="#42b983" viewBox="0 0 16 16" width="25" height="25" class="bulb"><path d="M8 1.5c-2.363 0-4 1.69-4 3.75 0 .984.424 1.625.984 2.304l.214.253c.223.264.47.556.673.848.284.411.537.896.621 1.49a.75.75 0 0 1-1.484.211c-.04-.282-.163-.547-.37-.847a8.456 8.456 0 0 0-.542-.68c-.084-.1-.173-.205-.268-.32C3.201 7.75 2.5 6.766 2.5 5.25 2.5 2.31 4.863 0 8 0s5.5 2.31 5.5 5.25c0 1.516-.701 2.5-1.328 3.259-.095.115-.184.22-.268.319-.207.245-.383.453-.541.681-.208.3-.33.565-.37.847a.751.751 0 0 1-1.485-.212c.084-.593.337-1.078.621-1.489.203-.292.45-.584.673-.848.075-.088.147-.173.213-.253.561-.679.985-1.32.985-2.304 0-2.06-1.637-3.75-4-3.75ZM5.75 12h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1 0-1.5ZM6 15.25a.75.75 0 0 1 .75-.75h2.5a.75.75 0 0 1 0 1.5h-2.5a.75.75 0 0 1-.75-.75Z"></path></svg></span></p>
      <span v-for="(letter, index) in text" :key="index" class="letter">{{ letter }}</span>
    </span>
  </div>
</template>

<script>
export default {
name: "Intro_page",
data(){
    return{
        text: 'EMPOWERING WOMEN AND CHILDREN WORLDWIDE'
    }
},
mounted() {
    this.animateText();
      },
methods: {
    animateText() {
          const letters = this.text.split('');
          letters.forEach((letter, index) => {
            setTimeout(() => {
              this.text = letters.slice(0, index + 1).join('');
            }, index * 500);
          });
        },
    }
}
</script>

<style scoped>
#intro {
    height: 90vh;
    overflow: hidden;
}
.back-img {
    width: 100%;
}
.intro-text {
    font-size: 17px;
    font-weight: 900;
    letter-spacing: 1px;
    width: 100%;
    color: whitesmoke;
}
.btnn {
  margin-top: 60vh;
  margin-left: -35vw;
  width: 30vw;
  height: 80px;
  scale: 0.8;
  background-color: #42b983;
  position: absolute;
  border: none;
  padding: 10px 8px 10px 8px;
}
.typed-text {
        display: inline-block;
      position: absolute;
      top: 0;
      left: 50%;
      overflow: hidden;
      transform: translateX(-50%);
      white-space: nowrap;
    }
    .lhead {
        font-size: 30px;
        font-weight: 900;
        letter-spacing: 1px;
        color: #42b983;
        margin-top: 30px;
    }

    .letter {
      opacity: 0;
      animation: appear 0.5s forwards, fall 1s forwards;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 3px;
    }
    .animation-container {
      position: relative;
      height: 40vh;
    }
    .bulb {
        margin-top: -7px;
    }


@media only screen and (max-width: 600px) {
    #intro {
        height: fit-content;
    }
    .back-img {
    width: 100%;
}
    .intro-text {
    margin-top: 0px;
    font-size: 20px;
    font-weight: 900;
    letter-spacing: 1px;
    width: 50%;
    /* color: #209444; */
    display: none;
    }
    .lhead {
        font-weight: 800;
        letter-spacing: 1px;
        padding-top: 30px;
        margin-bottom: -5px;
        scale: 0.7;
    }
    .typed-text {
        margin-top: 0px;
        width: 100%;
        font-weight: 500;
    }
    .letter{
        font-size: 12px;
        letter-spacing: 1px;
    }
    .animation-container {
      margin-top: -40px;
    }
    .btnn {
      display: none;
    }
}

    @keyframes appear {
      to {
        opacity: 1;
      }
    }
    @keyframes fall {
      to {
        top: 80vh; /* Adjust the distance to fall */
      }
    }
</style>