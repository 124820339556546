<template>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi, pariatur!</p>
</template>

<script>
export default {
name: "Supp_ort",
components: {
    
}
}
</script>

<style scoped>

</style>