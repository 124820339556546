import { createRouter, createWebHistory } from 'vue-router'
import store from "../store/store";
import {
  LOADING_SPINNER_SHOW_MUTATION,
} from "../store/storeconstants";

import HomeView from '../views/HomeView.vue'
import ContactView from '../views/Contactview.vue'
import SupportView from '../views/Support.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/contactus',
    name: 'contactus',
    component: ContactView
  },
  {
    path: '/support',
    name: 'support',
    component: SupportView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  async scrollBehavior() {
        
    store.commit(LOADING_SPINNER_SHOW_MUTATION, true);
    await new Promise(r=>setTimeout(r,1000));
      store.commit(LOADING_SPINNER_SHOW_MUTATION, false);
    document.getElementById('app').scrollIntoView({behavior:'smooth'});
 }
})

export default router
