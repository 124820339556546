<template>
    <div class="row">
        <div class="col-12">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.4527638686304!2d7.473236474623491!3d6.46418272385059!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1044a24bc71a8a01%3A0xf054a3bb6997f868!2sChief%20Okeanonife%20Innocent%20.C.%20St%2C%20GRA%2C%20Enugu%20400102%2C%20Enugu!5e0!3m2!1sen!2sng!4v1705519869226!5m2!1sen!2sng" width="80%" height="400px" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" data-aos="fade-left" data-aos-duration="3000"></iframe>
        </div>
        <center><div class="col-md-12" style="width: 80%;">
            <p class="locate">
                For inquiries and assistance, reach out to us at <span><a href="mailto:lawyersfoundationempowerment12@yahoo.com">lawyersfoundationempowerment12@yahoo.com</a></span> or call us at  <span><a href="phone:+2348037144894">+2348037144894</a></span> or <span><a href="phone:+2348079038784">+2348079038784</a></span>. Our dedicated team is ready to provide support and address any questions you may have. Connect with us for prompt and reliable assistance. Alternatively, utilize the form below to send a message to our foundation. We welcome your input and look forward to assisting you.
            </p>
        </div></center>
        <div class="row">
                <div class="col-md-6">
                    <form class="formbox"
                    data-aos="zoom-in"
                    data-aos-duration="2500"
                    data-aos-delay="500"
                    data-aos-once="true">
                    <p class="formtext">
                        <label>Name<span id="esteris">*</span></label>
                        <input type="text" name="name" title="Input Your Fullname Please" v-model="name" required>
                    </p>
                    <p class="formtext">
                        <label>Location<span id="esteris">*</span></label>
                        <input type="text" name="location" v-model="location" required>
                    </p>
                    <p class="formtext">
                        <label>Email Address<span id="esteris">*</span></label>
                        <input type="email" name="email" v-model="email" required>
                    </p>
                    <p class="formtext">
                        <label>Phone Number<span id="esteris">*</span></label>
                        <input type="text" name="phone" v-model="phonenumber" required>
                    </p>
                    <p class="full formtext">
                        <label>Message</label>
                        <textarea name="message" cols="30" rows="5" v-model="message"></textarea>
                    </p>
                    <p class="full">
                        <center><button v-on:click="contactUs()" class="formtext-btn">SUBMIT</button></center>
                    </p>
                </form>
                </div>
                <div class="col-md-6">
                    
                </div>
        </div>
    </div>
    <Base />
</template>

<script>
import Base from "../components/Base.vue";
export default {
name: "Contac_Us",
components: {
    Base,
},
data(){
    return{
        name:"",
        location:"",
        email:"",
        phonenumber:"",
        message:""
    }
},
methods:{
    // async contactUs(){
    //               const NewInformation = {
    //                   id: Math.floor(Math.random() * 100000),
    //                   name: this.name,
    //                   location: this.location,
    //                   email: this.email,
    //                   phonenumber: this.phonenumber,
    //                   message: this.message
    //               }
    //               fetch("https://script.google.com/macros/s/AKfycbxQoI1VTH3S_TAdL0MYNwDNJ99q79Jm4OzBZUGs4_9dt0X9qWcLJmZXa7dnxidwPL_4/exec", NewInformation)
    //                 .then(response => response.json())
    //                 .then(data => (this.postId = data.id))
    //                 .then(console.log("it is done"))
    //                 window.alert(this.name+ " message has been delivered");
    // }
    async contactUs() {
                const NewInformation = {
                    id: Math.floor(Math.random() * 100000),
                    name: this.name,
                    location: this.location,
                    email: this.email,
                    phonenumber: this.phonenumber,
                    message: this.message
                };

                try {
                    const response = await fetch("https://script.google.com/macros/s/AKfycbxX8x4AJGmem13h5DZ5q7Yhb0DMkcW7yHCTzNUymOch6kIiUXHQ6v1umSHxpv8C7rM/exec", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(NewInformation)
                    });

                    const data = await response.json();

                    if (data.status === 'success') {
                    console.log("Message has been delivered");
                    window.alert(this.name + " your message has been delivered.");
                    } else {
                    console.error("Failed to deliver message.");
                    window.alert("There was an issue delivering your message.");
                    }
                } catch (error) {
                    console.error("Error:", error);
                    window.alert("An error occurred while delivering your message.");
                }
        }
    }
}
</script>

<style scoped>
.locate {
    text-align: center;
    font-size: 15px;
}
label {
    display: flex;
    color: white;
    font-weight: bold;
}
.sub-button {
    padding: 10px;
    font-size: 15px;
    letter-spacing: 2px;
    border-radius: 10px;
    background-color: rgb(3, 101, 2);
    color: white;
    border: none;
}
input, textarea {
    width: 100%;
    border-radius: 10px;
}
#esteris{
    color: rgb(240, 42, 42);
    padding-left: 3px;
    font-weight: 700;
 }

 .formbox {
    border: 1px solid #42b983;
    padding: 20px 30px 20px 30px;
    margin: 20px;
    background-color: #42b983;
    border-radius: 15px;
    box-shadow: 4px 3px 8px 1px #42b983;
    -webkit-box-shadow: 4px 3px 8px 1px #bbb7b7;
 }
input {
    border: 1px solid #42b983;    
    outline: transparent;
    height: 50px;
    letter-spacing: 1px;
    font-size: 19px;
}
.formtext-btn {
    width: 120px;
    height: 40px;
    letter-spacing: 1px;
    font-weight: 900;
    border-radius: 10px;
    border: none;
    color: #42b983;
}
</style>