<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <Loader v-if="showLoading"></Loader>
  <NavBar id="navbar"/>
  <router-view />
</template>

<script>
import AOS from 'aos'
import {mapState} from 'vuex';
import store from "./store/store";
import {LOADING_SPINNER_SHOW_MUTATION,} from "./store/storeconstants";
import NavBar from "../src/components/NavBar.vue";
import Loader from "./components/loader.vue";
export default {
  name: "App",
  components: {
    NavBar,
    Loader
  },
  computed: {
    ...mapState({
      showLoading: (state) => state.showLoading,
    }),
  },
  async mounted() {
    AOS.init({ once: true });
    store.commit(LOADING_SPINNER_SHOW_MUTATION, true);
      await new Promise(r=>setTimeout(r,500));
        store.commit(LOADING_SPINNER_SHOW_MUTATION, false);
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#navbar {
}
</style>
