<template>
  <div class="home">
    <Intro />
    <Body />
    
  </div>
  <Base />
</template>

<script>
// @ is an alias to /src
import Intro from '@/components/Intro.vue';
import Body from '@/components/Body.vue';
import Base from "../components/Base.vue";
export default {
  name: 'HomeView',
  components: {
    Intro,
    Body,
    Base
  }
}
</script>
