<template>
 <section>
  <nav class="navbar navbar-expand-lg navbar-light" style="background-color: #ffffff;">
    <div class="container-fluid">
      <img
        class="img-rounded"
        src="../assets/Lawyers_foundation_crest.png"
        alt="tech_origin_class_image"
        style="height: 50px; padding: 0px 3px 4px 5px"
      />
      <routerLink class="navbar-brand fw-bold display-5" to="/" id="titlee"
        >Lawyers Foundation<span style="font-size: 12px; color: black;"><br>for women and children empowerment</span></routerLink
      >
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#main-nav"
        aria-controls="main-nav"
        aria-expanded="true"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse navbar-collapse justify-content-end align-center"
        id="main-nav"
      >
        <ul class="navbar-nav">
          <li class="nav-item me-4 py-2 menufontfamily menuItem">
            <routerLink class="topp" to="/">Home</routerLink>
          </li>
          <li class="nav-item me-2 py-2 menufontfamily menuItem">
            <router-link class="topp" to="/about">About Us</router-link>
          </li>
          <li class="nav-item me-4 py-2 menufontfamily menuItem">
            <routerLink class="topp" to="/contactus">Contact Us</routerLink>
          </li>
        </ul>
        <hr>
      </div>
    </div>
  </nav>
  </section>
  


</template>

<script>
export default {
name: "Nav_bar"
}
</script>

<style scoped>
nav {
  padding: 10px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
.topp {
  text-decoration: none;
}
#titlee {
  color: #42b983;
}


</style>
