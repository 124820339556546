<template>
  <div class="loading-box">
    <div style="display: block;">
      <img src="../assets/Lawyers_foundation_crest.png" class="crest"/>
         <p class="fw-bold display-5" style="color: #42b983; font-size: 20px;"
         >Lawyers Foundation<span style="font-size: 13px; color: black;"><br>for women and children empowerment</span></p>
    </div>
  </div>
</template>
  <script>
export default {
  name: "LoaDer",
  data() {
    return {};
  },
  methods: {},
};
</script>
  
  <style scoped>
.loading-box {
  opacity: 0.9;
  background-color: white;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.crest {
  width: 120px;
  height: 120px;
  animation-name: crest;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
@keyframes crest {
  0% {
    scale: 0.7;
  }
  100% {
    scale: 0.9;
  }
}

</style>