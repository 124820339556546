<template>
  <section id="body">
    <div class="row conx">
       <div class="col-md-5">
          <div class="cover-photo">
            <img src="../assets/talk_lawyer.jpg" class="imgg"/>
          </div>
       </div> 
       <div class="col-md-7">
          <div class="my-4"
          data-aos="zoom-in"
          data-aos-duration="2500"
          data-aos-delay="500"
          data-aos-once="true">
            <h4 class="head">Who are we ?</h4>
            <p class="text p-3">This Lawyers Foundation is a non-governmental organization commited to the promotion and protection of people's rights. We stand as a beacon of hope for women and children, championing their rights and offering them the transformative power of education to both Women and Children. In a world where the vulnerable often face systemic challenges, LFWCE serves as a catalyst for positive change, illustrating the profound impact that legal advocacy and education can have on the empowerment of individuals and the betterment of society as a whole. The foundation believes that providing access to education is a transformative tool that breaks the cycle of poverty, empowers individuals, and fosters positive societal change.<span class="px-1"><router-link class="link4more" to="/about">Learn more....</router-link></span></p>
          </div>
        </div> 
    </div>
  </section>

  <section id="activities">
    <h4 class="head py-3">OUR ACTIVITIES THUS FAR...</h4>
    <div class="row">
      <div class="col-md-5" id="box">
        <div class="box"
        data-aos="zoom-in"
          data-aos-duration="2500"
          data-aos-delay="500"
          data-aos-once="true">
          <p class="act"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-bank" viewBox="0 0 16 16">
            <path d="m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.5.5 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89zM3.777 3h8.447L8 1zM2 6v7h1V6zm2 0v7h2.5V6zm3.5 0v7h1V6zm2 0v7H12V6zM13 6v7h1V6zm2-1V4H1v1zm-.39 9H1.39l-.25 1h13.72z"/>
          </svg><span class="ps-2"
          data-aos="zoom-in"
          data-aos-duration="2500"
          data-aos-delay="1000"
          data-aos-once="true">TRAINING PROGRAMME ON BUDGET AND BUDGET TRACKING</span></p>

          <p class="bdy"
          data-aos="zoom-in"
          data-aos-duration="2500"
          data-aos-delay="1000"
          data-aos-once="true">In 2012, the Foundation undertook the training of Catholic Women Organization of St Michael's Parish Ugwu na Agbo on the 6th day of August, during their August Return of 2012 on budget and budget tracking.
          This topic became important because every year the three tiers of government budgeted money particularly as it concerned the welfare of women and children in Edem as regards education, health, welfare and feeding. The women hardly knew of such provisions and could not track their performance. This programme enabled the women to go in and ask questions and monitor programmes specially budgeted for them through the office of the Chairman, Nsukka Local Government, the office of the Hon Member representing Nsukka West State Constituency, the office of the senator representing Enugu North Senatorial Zone and different ministries and department of both the federal and state government. It was an eye opener that they knew nothing about budgets and budget tracking.</p>
        </div>
      </div>
      <div class="col-md-5" id="box">
        <div class="box"
        data-aos="zoom-in"
          data-aos-duration="2500"
          data-aos-delay="500"
          data-aos-once="true">
          <p class="act"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-globe-europe-africa" viewBox="0 0 16 16">
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0M3.668 2.501l-.288.646a.847.847 0 0 0 1.479.815l.245-.368a.81.81 0 0 1 1.034-.275.81.81 0 0 0 .724 0l.261-.13a1 1 0 0 1 .775-.05l.984.34q.118.04.243.054c.784.093.855.377.694.801-.155.41-.616.617-1.035.487l-.01-.003C8.274 4.663 7.748 4.5 6 4.5 4.8 4.5 3.5 5.62 3.5 7c0 1.96.826 2.166 1.696 2.382.46.115.935.233 1.304.618.449.467.393 1.181.339 1.877C6.755 12.96 6.674 14 8.5 14c1.75 0 3-3.5 3-4.5 0-.262.208-.468.444-.7.396-.392.87-.86.556-1.8-.097-.291-.396-.568-.641-.756-.174-.133-.207-.396-.052-.551a.33.33 0 0 1 .42-.042l1.085.724c.11.072.255.058.348-.035.15-.15.415-.083.489.117.16.43.445 1.05.849 1.357L15 8A7 7 0 1 1 3.668 2.501"/>
          </svg><span class="ps-2"
          data-aos="zoom-in"
          data-aos-duration="2500"
          data-aos-delay="1000"
          data-aos-once="true">TRAINING PROGRAMME ON POLITICAL PARTICIPATION BY WOMEN</span></p>

          <p class="bdy"
          data-aos="zoom-in"
          data-aos-duration="2500"
          data-aos-delay="1000"
          data-aos-once="true">This training programme organized for faith based organizations and community based organizations in 2014 and 2015 before the Nigeria's general election of 2015 sought to create awareness for the obvious need for the participation of women in politics that were hitherto left for the women using the figures from Independent National Electoral Commission. 2011 voters list in Enugu North Senatorial Zone that showed the strength of women and the possibility of using their numbers to change the corrupt ridden political space.</p>
        </div>
      </div>
      <div class="col-md-5" id="box">
        <div class="box"
        data-aos="zoom-in"
          data-aos-duration="2500"
          data-aos-delay="500"
          data-aos-once="true">
          <p class="act"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="30" height="30" fill="currentColor" ><path d="M12.75 2.75V4.5h1.975c.351 0 .694.106.984.303l1.697 1.154c.041.028.09.043.14.043h4.102a.75.75 0 0 1 0 1.5H20.07l3.366 7.68a.749.749 0 0 1-.23.896c-.1.074-.203.143-.31.206a6.296 6.296 0 0 1-.79.399 7.349 7.349 0 0 1-2.856.569 7.343 7.343 0 0 1-2.855-.568 6.205 6.205 0 0 1-.79-.4 3.205 3.205 0 0 1-.307-.202l-.005-.004a.749.749 0 0 1-.23-.896l3.368-7.68h-.886c-.351 0-.694-.106-.984-.303l-1.697-1.154a.246.246 0 0 0-.14-.043H12.75v14.5h4.487a.75.75 0 0 1 0 1.5H6.763a.75.75 0 0 1 0-1.5h4.487V6H9.275a.249.249 0 0 0-.14.043L7.439 7.197c-.29.197-.633.303-.984.303h-.886l3.368 7.68a.75.75 0 0 1-.209.878c-.08.065-.16.126-.31.223a6.077 6.077 0 0 1-.792.433 6.924 6.924 0 0 1-2.876.62 6.913 6.913 0 0 1-2.876-.62 6.077 6.077 0 0 1-.792-.433 3.483 3.483 0 0 1-.309-.221.762.762 0 0 1-.21-.88L3.93 7.5H2.353a.75.75 0 0 1 0-1.5h4.102c.05 0 .099-.015.141-.043l1.695-1.154c.29-.198.634-.303.985-.303h1.974V2.75a.75.75 0 0 1 1.5 0ZM2.193 15.198a5.414 5.414 0 0 0 2.557.635 5.414 5.414 0 0 0 2.557-.635L4.75 9.368Zm14.51-.024c.082.04.174.083.275.126.53.223 1.305.45 2.272.45a5.847 5.847 0 0 0 2.547-.576L19.25 9.367Z"></path></svg><span class="ps-2"
            data-aos="zoom-in"
          data-aos-duration="2500"
          data-aos-delay="1000"
          data-aos-once="true">LEGAL REPRESENTATIONS PROGRAMME</span></p>

          <p class="bdy" style="display: block;"
          data-aos="zoom-in"
          data-aos-duration="2500"
          data-aos-delay="1000"
          data-aos-once="true">
            <span id="listd"><span style="font-weight: 900;">+ </span> Legal representation of Mr Nwabueze from Edem in 2012 who was detained at the CID Enugu for alleged robbery of one Mr Peter Asogwa who sold his property in the day and got robbed at night. Mr Nwabueze was the agent who facilitated the sale. He was released from police as there was not prime facie evidence that he committed the crime. </span><br><br>
            <span id="listd"><span style="font-weight: 900;">+ </span> Legal representation of Mrs Oranugo, a woman deprived of her property at Inyi Oji River was defended in 2022 - 2023 to stop the stepson from depriving her of property she acquired with her late husband who she married under the Act.</span><br><br>
            <span id="listd"><span style="font-weight: 900;">+ </span> Legal representation for Mrs Josephine from Edem who was charged of murder but who did not take part in mob action that lead to the death of a man in the community. She was released from prison in August 2011.</span>
          </p>
        </div>
      </div>
      <div class="col-md-5" id="box">
        <div class="box"
        data-aos="zoom-in"
          data-aos-duration="2500"
          data-aos-delay="500"
          data-aos-once="true">
          <p class="act"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-hospital" viewBox="0 0 16 16">
          <path d="M8.5 5.034v1.1l.953-.55.5.867L9 7l.953.55-.5.866-.953-.55v1.1h-1v-1.1l-.953.55-.5-.866L7 7l-.953-.55.5-.866.953.55v-1.1zM13.25 9a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25zM13 11.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25zm.25 1.75a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25zm-11-4a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5A.25.25 0 0 0 3 9.75v-.5A.25.25 0 0 0 2.75 9zm0 2a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25zM2 13.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25z"/>
          <path d="M5 1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1a1 1 0 0 1 1 1v4h3a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3V3a1 1 0 0 1 1-1zm2 14h2v-3H7zm3 0h1V3H5v12h1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1zm0-14H6v1h4zm2 7v7h3V8zm-8 7V8H1v7z"/>
          </svg><span class="ps-2"
          data-aos="zoom-in"
          data-aos-duration="2500"
          data-aos-delay="1000"
          data-aos-once="true">PROMOTING HEALTHCARE AWARENESS</span></p>

          <p class="bdy" style="display: block;"
          data-aos="zoom-in"
          data-aos-duration="2500"
          data-aos-delay="1000"
          data-aos-once="true">
            <span id="listd">Sensitization to Edem town that has 47 villages on the need to do a blood pressure check and screening for diabetes mellitus in 2013 & 2022. The essence of the exercise is to identify those who have health problem, offer advice and treatment and sensitize them to develop the culture of going to clinic/hospitals to seek for proper medical attention.<br><br> Total number screened for high blood pressure and diabetes mellitus - 300 & 450:  male - 117 (38.6%), female - 186 (61.4%).</span><br><br>
            <span id="listd"><span style="font-weight: 900;">+ </span> Number detected to have high blood pressure - 38 </span><br>
            <span id="listd"><span style="font-weight: 900;">+ </span> Number detected to have high sugar level - 14 </span><br>
            <span id="listd"><span style="font-weight: 900;">+ </span> Number of medical professional engaged for the exercise - 8</span>
          </p>
        </div>
      </div>
    </div>
  </section>


  <section id="accord">
    <h4 class="FAQ">FREQUENTLY ASKED QUESTIONS</h4>
    <div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingOne">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
        WHAT ARE WE KNOWN FOR ?
      </button>
    </h2>
    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">LFWCE is a non-governmental organization commited to the promotion and protection of people's rights. We stand as a beacon of hope for women and children, championing their rights and offering them the transformative power of education to both Women and Children.</div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
        OUR CONTRIBUTIONS
      </button>
    </h2>
    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">At LFWCE we subcribe annually a total amount of &#8358;10,000 by each member. Payments are made directly through our Financial Secretary which are later reviewed every five years.</div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
        HOW TO REACH OUR HEAD-OFFICE ?
      </button>
    </h2>
    <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body"><p class="acc-head">IF A FLIGHT WAS TAKEN</p>
      <p>Coming from Emene <span style="color: #42b983;">>>></span> Enter bus going to Abakpa junction<span style="color: #42b983;">>>></span>Enter a bus going to New Market<span style="color: #42b983;">>>></span>Enter a tricycle going to SARS<span style="color: #42b983;">>>></span>Then you can easily located plot H/8 Golf Course layout in the estate</p>
    <p>COMING FROM HOLYGHOST OR PEACE PARK ENUGU ?</p>
    <p>Enter a bus going to New market<span style="color: #42b983;">>>></span>Enter a tricyle going to SARS<span style="color: #42b983;">>>></span>Then you can easily located plot H/8 Golf Course layout in the estate</p>
    
    <p>Better still contact ******** for better direction.</p></div>
    </div>
  </div>
</div>
  </section>
</template>

<script>
export default {
name:"Bo_dy",
}
</script>

<style scoped>
#body {
    background-color: whitesmoke;
    height: 60vh;
    margin-top: -20vh;
}
.cover-photo {
  overflow: hidden;
  width: 100%;
  scale: 0.8;
  border-radius: 15px;
}
.conx {
  height: 60vh;
  overflow: hidden;
}
.imgg {
  height: 60vh;
}
.head {
  color: #42b983;
  font-size: 25px;
  font-weight: 800;
}
.text {
  font-size: 18px;
  line-height: 32px;
}
.link4more {
  font-size: 16px;
  text-decoration: none;
}
.FAQ {
  font-size: 22px;
  text-align: start;
  padding: 10px;
  font-weight: 800;
  color: #42b983;
}
#accord {
  width: 80%;
  margin: 8px;
  text-align: start;
  letter-spacing: 1px;
}
.act {
  font-size: 14px;
  line-height: 15px;
  padding: 8px;
  color: #42b983;
  font-weight: 900;
}
.box {
  height: 400px;
  border: 1px solid whitesmoke;
  border-radius: 20px;
  box-shadow: 4px 3px 8px 1px #42b983;
  -webkit-box-shadow: 4px 3px 8px 1px #bbb7b7;
  margin: 20px;
}
#box {
  margin: auto auto;
  margin-bottom: 30px;
}
.bdy {
  font-size: 14px;
  line-height: 30px;
  text-align: start;
  padding: 15px;
  height: 300px;
  overflow: scroll;

}
.bdy::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.bdy {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
@media only screen and (max-width: 600px) {
   .imgg {
    height: 300px;
   }
   .conx {
    height: fit-content;
   }
   #body {
    background-color: whitesmoke;
    height: fit-content;
    margin-top: -20vh;
}
.head {
  color: #42b983;
  font-size: 20px;
  font-weight: 800;
  margin-top: -10px;
}
.FAQ {
  font-size: 20px;
}
#accord {
  width: 98%;
}
.text {
  font-size: 14px;
  line-height: 28px;
  margin-top: -15px;
}
.link4more {
  font-size: 16px;
  text-decoration: none;
}
}
</style>